import React, { Component } from 'react';
import styled from 'styled-components';
import device from '../../../Assets/Responsive/breakpoints';

const Container = styled.section`
    height: 50vh;/* Since pageSplitTime is 1.4 */
    width:100%;
    /* border: 1px solid blue; */
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    positions: relative;
`;
const ResumeLink = styled.a`
    text-decoration: underline;
    color: inherit;
    opacity: 0.9;
    font-style: italic;
    white-space: nowrap;
    @media ${device.mobileS} {
      padding: 30px;
      font-size: 20px;
    }
    @media ${device.mobileM} {
      padding: 30px;
      font-size: 23px;
    }
    @media ${device.mobileL} {
      padding: 30px;
      font-size: 24px;
    }
    @media ${device.tablet} {
      padding: 80px;
      font-size: 40px;
    }
    @media ${device.laptop} {
      padding: 90px;
      font-size: 45px;
    }
`;

const AboutMeDescription = styled.span`
  font-family: 'AvenirRoman';
  font-size: 24px;
  text-align: center;
  z-index:2;
  @media ${device.mobileS} {
    padding: 30px;
    font-size: 20px;
  }
  @media ${device.mobileM} {
    padding: 30px;
    font-size: 23px;
  }
  @media ${device.mobileL} {
    padding: 30px;
    font-size: 24px;
  }
  @media ${device.tablet} {
    padding: 80px;
    font-size: 40px;
  }
  @media ${device.laptop} {
    padding: 90px;
    font-size: 45px;
  }
`;

class AboutMe extends Component {
  render() {
    return (
      <Container>
        <AboutMeDescription>
          Building products, one block at a time. With a strong focus on user-centric solutions.
          Driven by a deep passion for the decentralized web.
          {' '}
          <ResumeLink
            href="https://wurdig.infura-ipfs.io/ipfs/QmPYohPZKJYXeRSWvQAmRN1LBn8Y2ezJbiWNidjkSoeEAS"
            target="_blank"
            rel="noopener noreferrer"
          >
            My resume

          </ResumeLink>
        </AboutMeDescription>
      </Container>
    );
  }
}

export default AboutMe;
